import {Inject, Injectable} from "@angular/core";
import {PostPayloadInterface} from "../utilities/post-payload.interface";
import {HttpClient} from "@angular/common/http";
import {BASE_URL, BaseRepository, PositoryErrorHandlerService, Path, RepoPath} from "ng-pository";

@RepoPath('/mgmt/admin/keycloak')
@Injectable({
  providedIn: 'root'
})
export class UserRepository extends BaseRepository<any> {

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/users/by-common-distributor/{userId}')
  getUsersByDistributor(userId: string) {
    return super.get();
  }

  @Path('/users')
  getUsers() {
    return super.get();
  }

  @Path('/connections')
  getUserConnections(ids:string[]) {
    return super.post(ids);
  }

  @Path('/users/{role}')
  getUsersByRole(role: string) {
    return super.get();
  }

  @Path('/users/by-email/?email={email}')
  getUserById(email: string) {
    return super.get();
  }

  @Path('/roles')
  getRoles() {
    return super.get();
  }

  @Path('/users')
  saveUser(user: any) {
    return super.post({
      type: "AddUser",
      id: 0,
      attribute: user
    });
  }

  @Path('/users')
  updateUser(user: any) {
    return super.put({
      type: "Update",
      id: 0,
      attribute: user
    });
  }

  @Path('/users/update-password')
  updatePassword(email: string) {
    return super.put({
      type: "UpdatePassword",
      id: 0,
      attribute: email
    });
  }

  @Path('/users/{keycloakId}')
  deleteUser(keycloakId: string) {
    return super.delete();
  }

}

