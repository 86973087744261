<nx-card class="table-card-viewer">
  <page-list-view-header
    [title]="'webhook.headline'"
    [menuWantedInMobileView]="false"
    [onlyAddNewButtonWantedInMobileView]="false"
  ></page-list-view-header>
  <ng-container *ngFor="let type of WebhookType | keyvalue">
    <nx-card class="viewer nx-margin-bottom-2m">
      <nx-toolbar class="justify-content-between d-flex">

        <div class="webhook-headline">
          <h4 nxHeadline="subsection-small" class="back-right">{{ 'webhook.' + type.key | caption }}</h4>
        </div>

        <button nxButton="primary small" type="button" (click)="openNewDialog(type.key)">
          <nx-icon name="save-o" class="nx-margin-right-2xs"></nx-icon>
          {{ 'general.new' | caption }}
        </button>

      </nx-toolbar>
      <ng-container *ngFor="let webhook of models | skipInvalidItem:'type':type.key; let last = last">

        <div class="row" style="padding: 0 32px">
          <div class="col-10">
            <div class="row mt-3">
              <div class="col-xxl-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="people-group" class="me-1"></nx-icon>
                    {{ 'webhook.partner' | caption }}:
                  </p>
                  <p class="ms-2 info">{{ webhook.partner.name }}</p>
                </div>
              </div>
              <div class=" col-xxl-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="handshake" class="me-1"></nx-icon>
                    {{ 'webhook.signatureUrl' | caption }}:

                  </p>
                  <p class="ms-2 info">{{ webhook.signatureUrl | shortenIfMobile:mobile }}
                    <button *ngIf="mobile" class="ms-2" nxPlainButton (click)="copyToClipboard(webhook.signatureUrl)">
                      <nx-icon name="copy"></nx-icon>
                    </button>
                  </p>
                </div>
              </div>
              <div class="col-xxl-4">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="link" class="me-1"></nx-icon>
                    {{ 'webhook.url' | caption }}:

                  </p>
                  <p class="ms-2 info">{{ webhook.url | shortenIfMobile:mobile }}
                    <button *ngIf="mobile" class="ms-2" nxPlainButton (click)="copyToClipboard(webhook.url)">
                      <nx-icon name="copy"></nx-icon>
                    </button>
                  </p>
                </div>
              </div>
              <div class=" col-12">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="label">
                    <nx-icon name="mask" class="me-1"></nx-icon>
                    {{ 'webhook.securityKey' | caption }}:

                  </p>
                  <p class="ms-2 info">{{ webhook.securityKey | shortenIfMobile:mobile }}
                    <button *ngIf="mobile" class="ms-2" nxPlainButton (click)="copyToClipboard(webhook.securityKey)">
                      <nx-icon name="copy"></nx-icon>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!mobile" class="col-2 d-flex flex-column align-items-end justify-content-center">
            <button nxButton="secondary small" type="button" (click)="openEditDialog(webhook)" class="mb-2">
              <nx-icon name="pencil" class="nx-margin-right-2xs"></nx-icon>
              {{ 'general.edit' | caption }}
            </button>
            <button nxButton="danger small" type="button" (click)="delete(webhook)">
              <nx-icon name="trash" class="nx-margin-right-2xs"></nx-icon>
              {{ 'general.delete' | caption }}
            </button>
          </div>
        </div>
        <div *ngIf="mobile" class="d-flex flex-column align-items-end justify-content-center">
          <button nxButton="secondary small" type="button" (click)="openEditDialog(webhook)" class="mb-2">
            <nx-icon name="pencil" class="nx-margin-right-2xs"></nx-icon>
            {{ 'general.edit' | caption }}
          </button>
          <button nxButton="danger small" type="button" (click)="delete(webhook)">
            <nx-icon name="trash" class="nx-margin-right-2xs"></nx-icon>
            {{ 'general.delete' | caption }}
          </button>
        </div>
        <hr *ngIf="!last">

      </ng-container>
    </nx-card>
  </ng-container>
</nx-card>
