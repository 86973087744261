import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewChild
} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {KeycloakService} from "keycloak-angular";
import {User} from "../../../../models/user.model";
import {AUTH_USER_KEY, DISTRIBUTOR_KEY, USER_ROLES} from "../../../../utilities/constants";
import {UserService} from "../../../../services/model/user.service";
import {PartnerService} from "../../../../services/model/partner.service";
import {MyStorageService} from "../../../../services/my-storage.service";
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {VIEW_CHANGER_CONTAINER, ViewChanger} from "../../../../components/list-view-header/list-view-header.component";
import {UserCardComponent} from "../../../../components/list-view-card/usercard/user-card.component";
import {AbstractListPage} from "../../../../models/AbstractListPage";
import {NxViewportService} from "@aposin/ng-aquila/utils";
import {TableAction, TableCol, TableFilter} from "ng-aquila-datatable";
import {AbstractCaptionService} from "irf-caption";
import {DistributorService} from "../../../../services/model/distributor.service";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";
import {Distributor} from "../../../../models/distributor.model";

@Component({
  selector: 'app-users-for-distributor',
  templateUrl: './users-for-distributor.component.html',
  styleUrls: ['./users-for-distributor.component.scss']
})
@PageTest({
  path: ROUTING.User.basePlural,
  layout: DistributorLayoutComponent,
})
export class UsersForDistributorComponent extends AbstractListPage<User[], UserService> implements OnInit, OnChanges {
  colDef: TableCol[] = [];
  actionDef: TableAction[];
  listViewCard: Type<any> = UserCardComponent;


  @ViewChild('partner') partnerTemplate: TemplateRef<any>;

  viewChangers: VIEW_CHANGER_CONTAINER;


  constructor(viewportService: NxViewportService, cdr: ChangeDetectorRef, service: UserService, myStorageService: MyStorageService,
              translateService: AbstractCaptionService, private partnerService: PartnerService, private keycloakService: KeycloakService,private distributorService:DistributorService ) {
    super(viewportService, cdr, translateService, service, myStorageService);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.loadData();

  }

  private async loadData(): Promise<void> {
    this.actionDef = [
      new TableAction('eye', this.show(), await this.getTranslate('table.action.show'), this.distributorRowPermission()),
      new TableAction('pencil', this.edit(), await this.getTranslate('table.action.edit'), this.distributorRowPermission()),
      new TableAction('key', this.updatePassword(), await this.getTranslate('table.action.updatePassword'), this.distributorRowPermission()),
      new TableAction('trash', this.delete(), await this.getTranslate('table.action.delete'), this.distributorRowPermission()),
    ];
    this.colDef = [
      TableCol.newString('keycloakId', await this.getTranslate('user.keycloakId')),
      TableCol.newMailto('email', await this.getTranslate('user.email')),
      TableCol.newString('partners', await this.getTranslate('user.partners'), TableFilter.newDefaultText(), () => {
        return this.partnerTemplate
      }),
      TableCol.newString('distributors', await this.getTranslate('user.distributors'), TableFilter.newDefaultText(), () => {
        return this.partnerTemplate
      }),
      TableCol.newString('roles', await this.getTranslate('user.roles')),
    ];
    setTimeout(async () =>{
      const users = await lastValueFrom(this.service.getUsersByDistributor(this.authUser.id));
      const userIds : string[] = users.map(user => user.keycloakId);
      const connections = await lastValueFrom(this.service.repo.getUserConnections(userIds));

      for (const user of users) {
        user.partners = [];
        user.distributors = [];
        connections.partners.forEach(partner => {
          if(partner.users.map(u => u.userKey).includes(user.keycloakId) && !user.partners.map(p => p.id).includes(partner.id)){
            user.partners.push(partner);
          }
        });
        connections.distributors.forEach(partner => {
          if(partner.users.map(u => u.userKey).includes(user.keycloakId) && !user.distributors.map(p => p.id).includes(partner.id)){
            user.distributors.push(partner);
          }
        });
      }
      this.models = users;
    },300);

  }

  private updatePassword(): (row: any) => void {
    return async (user: any) => {
      await lastValueFrom(this.service.repo.updatePassword(user.email));
      if (user.email == this.authUser.email) {
        this.myStorageService.deleteFromCookies(AUTH_USER_KEY);
        await this.keycloakService.logout();
      } else {
        this.service.notificationService.showSuccess('general.success', 'general.success');
      }
    }
  }

  private delete(): (row: any) => void {
    return async (user: any) => {
      if (this.authUser.email == user.email) {
        await this.service.notificationService.showWarning("general.warning", "error.dontDeleteYourself");
      }
      this.service.notificationService.confirm('Figyelmeztetés', 'Biztos hogy törlöd?', 'general.ok', 'general.cancel').then(async result => {
        if (result.value) {
          await lastValueFrom(this.service.repo.deleteUser(user.keycloakId));
          if (user.partners) {
            for (const partner of user.partners) {
              await lastValueFrom(this.partnerService.repo.removeUsers(partner.id, [user.keycloakId]));
            }
          }
          this.loadData();
        }
      });
    }
  }

  override show(): (row: any) => void {
    return async (user: any) => this.service.navigateToUrl('distributor/users/show/' + user.email);
  }

  override edit(): (row: any) => void {
    return async (user: any) => this.service.navigateToUrl('distributor/users/edit/' + user.email);
  }

  override new(): () => void {
    return () => this.service.navigateToUrl('distributor/users/edit')
  }

}
