<nx-card class="viewer">
  <nx-card-header >
    <page-form-header [model]="user" [titleProperty]="'name'"></page-form-header>
  </nx-card-header>
  <page-form-tool-bar
    [saveSupplier]="saveUser()"
    [cancelSupplier]="cancel()"
    [modalOptions]="toolbarAddOptions"
  >

  </page-form-tool-bar>

  <forms-user-form *ngIf="user.keycloakId || (!user.keycloakId && !isEdit)"
                   [user]="user"
                   [(userPartner)]="userPartner"
                   [(userDistributor)]="userDistributor"
                   [displayForDistributors]="true"
  ></forms-user-form>
</nx-card>
