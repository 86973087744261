import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from "../../../models/user.model";
import {ROLE_INDEX, ROLES} from "../../../utilities/constants";
import {lastValueFrom} from "rxjs";
import {UserService} from "../../../services/model/user.service";
import {AbstractBaseComponent} from "common";

@Component({
  selector: 'forms-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends AbstractBaseComponent implements OnInit, OnChanges {

  roleIndex = ROLE_INDEX;
  staticRoles = ROLES;

  @Input() user: User;
  @Input() userPartner: any[];
  @Input() userDistributor: any[];
  @Output() userPartnerChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() userDistributorChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() withRoles: boolean = true;
  @Input() showAddress: boolean = true;
  @Input() displayForDistributors: boolean = false;

  roles: any[] = [];

  constructor(private service: UserService,) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.loadRoles();/*
    if (this.displayForDistributors) {
      console.log('clear house')
      console.log(this.userDistributor)
      this.userDistributor = [];
      console.log(this.userDistributor)
    }*/
  }

  private async loadRoles(): Promise<void> {
    this.roles = await lastValueFrom(this.service.repo.getRoles());
    this.roles = this.roles.filter(r => ROLES.includes(r.name));

  }


  userPartnerChangeSupplier(userPartner) {
    this.userPartnerChange.emit(userPartner);
  }

  userDistributorChangeSupplier(userDistributor) {
    this.userDistributorChange.emit(userDistributor);
  }
}
