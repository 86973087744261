<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="'measuringDevice.headline'"
  >
  </page-list-view-header>
  <div class="aquila-table-custom">
    <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef" [showAsList]="mobile"
                  [sort]="tableSort"></aquila-table>
  </div>
</nx-card>


<ng-template #myDetailTpl let-row="row" let-expanded="expanded">
  <div style="padding-left:35px;" class="w-100">
    <ng-container *ngIf="hasPermission">
      <ng-container *ngFor="let status of statuses">
        <button [disabled]="row.status==status" class="nx-margin-right-s" nxButton="tertiary small" type="button"
                (click)="changeStatus(row, status)"> {{ 'measuringDevice.' + status | caption }}
        </button>
      </ng-container>

    </ng-container>
  </div>
</ng-template>

<ng-template #colorTemplate let-value="value">
  <div [style]="'width:50px; height: 20px; background:'+value">
  </div>
</ng-template>
<ng-template #translateTemplate let-value="value">
  {{ 'measuringDevice.' + value | caption }}
</ng-template>

<ng-template #template>
  <div nxModalContent>
    <nx-card class="w-100 h-100">
      <nx-card-header>
        <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">
          {{ 'measuringDevice.dataExport' | caption }}
        </h3>
      </nx-card-header>
      <div nxRow="">
        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'chart.filter.start' | caption}}">
            <input name="start" type="local-datetime" nxInput nxDatefield
                   [nxDatepicker]="startDatePicker"
                   [nxMax]="endDate"
                   [(ngModel)]="startDate" required/>
            <nx-datepicker-toggle
              [for]="startDatePicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #startDatePicker></nx-datepicker>
          </nx-formfield>

        </div>

        <div nxCol="12,12,12,6">
          <nx-formfield nxLabel="{{'chart.filter.end' | caption}}">
            <input name="end" type="datetime" nxInput nxDatefield
                   [nxDatepicker]="endDatePicker"
                   [nxMax]="today"
                   [nxMin]="startDate"
                   [(ngModel)]="endDate" required/>
            <nx-datepicker-toggle
              [for]="endDatePicker"
              nxFormfieldSuffix
            ></nx-datepicker-toggle>
            <nx-datepicker #endDatePicker></nx-datepicker>
          </nx-formfield>
        </div>

      </div>
    </nx-card>
  </div>
  <div nxModalActions>
    <button nxButton="small secondary"
            type="button" (click)="closeModal()">{{ 'general.cancel' | caption }}
    </button>
    <button class="nx-margin-left-s" *ngIf="selected" nxButton="small primary"
            type="button" (click)="export()">{{ 'general.export' | caption }}
    </button>
  </div>
</ng-template>
