<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="'addon.headline'"
    [menuWantedInMobileView]="true"
    [onlyAddNewButtonWantedInMobileView]="false"
  ></page-list-view-header>
  <div class="aquila-table-custom">
    <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                  [showAsList]="mobile"></aquila-table>
  </div>
</nx-card>

<ng-template #test let-value="value">
  <ng-container *ngFor="let role of value">
    {{ role }},
  </ng-container>
</ng-template>
