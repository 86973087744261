<app-loader></app-loader>
<ng-container *ngIf="isInitRouteFinish">
  <page-sidebar></page-sidebar>
  <nx-sidepanel-outer-container class="example-container">
    <router-outlet></router-outlet>
  </nx-sidepanel-outer-container>

  <nx-footer class="position-relative z-3 footer-container m-0 p-0 d-flex justify-content-between align-items-center">
    <nx-footer-navigation class="align-self-center">
      <nx-footer-link class="d-flex">
        <a routerLink="/privacy-policy" class="mx-2">{{'privacy.menuLabel' | caption}}</a>
        <a routerLink="/terms-and-conditions" class="mx-2">{{'terms.menuLabel' | caption}}</a>
        <nx-footer-copyright class="mx-2 align-self-center d-flex align-items-center">
          <span>&copy; IRF solutions {{ currentYear }}</span>
        </nx-footer-copyright>
      </nx-footer-link>
    </nx-footer-navigation>
  </nx-footer>
</ng-container>
