<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="'detector.headline'"
  >
  </page-list-view-header>
  <div class="aquila-table-custom">
    <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                  [showAsList]="mobile"></aquila-table>
  </div>
</nx-card>
