<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="headerTitle"
    [(viewChangers)]="viewChangers"
    [menuWantedInMobileView]="true"
    [onlyAddNewButtonWantedInMobileView]="false"
  ></page-list-view-header>
  <ng-template [ngIf]="viewChangers['mapView'].show && mapModels">
    <app-map height="calc(100vh - 152px)"
             [models]="mapModels"
             [startZoom]="6"
             [markerClickSupplier]="markerClick()"
             [showYourPosition]="false"
             [countryCodes]="countryCodesInAddress"></app-map>
  </ng-template>
  <ng-template [ngIf]="viewChangers['tableView'].show">
    <div class="aquila-table-custom">
      <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                    [showAsList]="mobile"></aquila-table>
    </div>
  </ng-template>
</nx-card>

<ng-template #test let-value="value">
  <ng-container *ngFor="let role of value">
    {{ role }},
  </ng-container>
</ng-template>
