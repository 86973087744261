<ng-template #myDetailTpl let-row="row" let-expanded="expanded">
  <div style="padding-left:35px;" clsss="w-100">
    <a class="mr-3" href="javascript:void(0);"
       [downloadFile]="{url:row.downloadLink, fileName:row.fileName}">{{ 'general.download' | caption }}</a>
  </div>
</ng-template>

<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="'attachment.headline'"
  ></page-list-view-header>
  <div class="aquila-table-custom">
    <aquila-table
      [rows]="models"
      [colDefinitions]="colDef"
      [actionConfig]="actionDef"
      [expandableTemplate]="myDetailTpl"
      [showAsList]="mobile"
      [elementsPerPage]="10"
    >
    </aquila-table>
  </div>
</nx-card>
