import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MeasuringDevicesForDistributorComponent
} from "./measuring-devices-for-distributor/measuring-devices-for-distributor.component";
import {NxCardModule} from "@aposin/ng-aquila/card";
import {ComponentsModule} from "../../components/components.module";
import {
  ShowMeasuringDevicesForDistributorComponent
} from "./show-measuring-devices-for-distributor/show-measuring-devices-for-distributor.component";
import {NxGridModule} from "@aposin/ng-aquila/grid";
import {NxMessageModule} from "@aposin/ng-aquila/message";
import {
  MeasuringPointsForDistributorComponent
} from "./measuring-point/measuring-points-for-distributor/measuring-points-for-distributor.component";
import {
  ShowMeasuringPointsForDistributorComponent
} from "./measuring-point/show-measuring-points-for-distributor/show-measuring-points-for-distributor.component";
import {
  EditMeasuringPointsForDistributorComponent
} from "./measuring-point/edit-measuring-points-for-distributor/edit-measuring-points-for-distributor.component";
import {
  ContractsForDistributorComponent
} from "./contract-for-distributor/contracts-for-distributor/contracts-for-distributor.component";
import {
  EditContractsForDistributorComponent
} from "./contract-for-distributor/edit-contracts-for-distributor/edit-contracts-for-distributor.component";
import {
  ShowContractsForDistributorComponent
} from "./contract-for-distributor/show-contracts-for-distributor/show-contracts-for-distributor.component";
import {NxHeadlineModule} from "@aposin/ng-aquila/headline";
import {PipeModule} from "../../pipe/pipe.module";
import { UsersForDistributorComponent } from './user-for-distributor/users-for-distributor/users-for-distributor.component';
import { EditUsersForDistributorComponent } from './user-for-distributor/edit-users-for-distributor/edit-users-for-distributor.component';
import { ShowUsersForDistributorComponent } from './user-for-distributor/show-users-for-distributor/show-users-for-distributor.component';


@NgModule({
  declarations: [MeasuringDevicesForDistributorComponent, ShowMeasuringDevicesForDistributorComponent, MeasuringPointsForDistributorComponent, ShowMeasuringPointsForDistributorComponent, EditMeasuringPointsForDistributorComponent,
    ContractsForDistributorComponent,EditContractsForDistributorComponent,ShowContractsForDistributorComponent,UsersForDistributorComponent,EditUsersForDistributorComponent,ShowUsersForDistributorComponent],
  exports: [MeasuringDevicesForDistributorComponent, ShowMeasuringDevicesForDistributorComponent, MeasuringPointsForDistributorComponent, ShowMeasuringPointsForDistributorComponent, EditMeasuringPointsForDistributorComponent,
    ContractsForDistributorComponent,EditContractsForDistributorComponent,ShowContractsForDistributorComponent,UsersForDistributorComponent,EditUsersForDistributorComponent,ShowUsersForDistributorComponent],
    imports: [
        CommonModule,
        NxCardModule,
        ComponentsModule,
        NxGridModule,
        NxMessageModule,
        NxHeadlineModule,
        PipeModule
    ]
})
export class DistributorPageModule {
}
