import {Component, OnInit} from '@angular/core';
import {User} from "../../../../models/user.model";
import {lastValueFrom, map, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AUTH_USER_KEY, DISTRIBUTOR_KEY, USER_ROLES} from "../../../../utilities/constants";
import {
  AddAddressToModelComponent
} from "../../../../components/modal/add-address-to-model/add-address-to-model.component";
import {NxDialogService, NxModalRef} from "@aposin/ng-aquila/modal";
import {UserService} from "../../../../services/model/user.service";
import {NxCheckboxGroupChangeEvent} from "@aposin/ng-aquila/checkbox";
import {PartnerUser} from "../../../../models/partner.model";
import {PartnerService} from "../../../../services/model/partner.service";
import {FormToolBarOption} from "../../../../components/form-tool-bar/form-tool-bar-option.model";
import {Utilities} from "../../../../utilities/utilities";
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {DistributorService} from "../../../../services/model/distributor.service";
import {DistributorUser} from "../../../../models/distributor.model";
import {MyStorageService} from "../../../../services/my-storage.service";
import {KeycloakService} from "keycloak-angular";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";

@Component({
  selector: 'app-edit-users-for-distributor',
  templateUrl: './edit-users-for-distributor.component.html',
  styleUrls: ['./edit-users-for-distributor.component.scss']
})
@PageTest({
  path: ['edit/:email', '/edit'],
  pathPrefix: ROUTING.User.basePlural,
  layout: DistributorLayoutComponent,
})
export class EditUsersForDistributorComponent implements OnInit {
  private sub: Subscription;


  componentDialogRef!: NxModalRef<any>;
  toolbarAddOptions: FormToolBarOption[];

  roles: any[] = [];
  partners: any[] = [];
  userPartner: any[] = [];
  userDistributor: any[] = [];
  user: User = new User();
  isEdit: boolean = false;

  constructor(private service: UserService,
              private partnerService: PartnerService,
              private distributorService: DistributorService,
              private myStorageService: MyStorageService,
              private keycloakService: KeycloakService,
              private router: Router,
              private route: ActivatedRoute,
              public dialogService: NxDialogService) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      this.isEdit = (params['email'] != undefined);
      this.user = await lastValueFrom(this.service.getById(params['email'])
        .pipe(
          map(user => this.service.addExtendedPropertiesForModel(user))
        )
      );
      if (this.user == null) {
        this.user = new User();
      }
    });
    this.toolbarAddOptions = [
      // new FormToolBarOption(this.openAddAddressModal(), 'home', 'general.addressModalButton'),
    ]
  }


  saveUser(): () => void {
    return async () => {
      try {
        this.service.validate(this.user);

        if (!this.user.keycloakId) {
          this.saveProcess();
        } else {
          this.updateProcess();
        }


      } catch (e: any) {
        this.service.validationErrorHandler(e);
      }
    };
  }

  finishProcess() {
    const authUser = this.myStorageService.getFromCookies(AUTH_USER_KEY, {});
    if (authUser.email == this.user.email) {
      this.keycloakService.logout().then();
    } else {
      this.navigateToBaseOrDetailedView();
    }
  }

  cancel(): () => void {
    return () => this.navigateToBaseOrDetailedView();
  }

  navigateToBaseOrDetailedView() {
    if (this.myStorageService.getFromCookies('NAVIGATE_TO_DETAILED_VIEW_AFTER_EDIT', false)) {
      this.service.navigateToUrl('distributor/users/show/' + this.user.email);
    } else {
      this.service.navigateToUrl('distributor/users');
    }
  }

  private saveProcess() {
    this.service.repo.saveUser(this.user).subscribe(result => {
      this.user = result;
      if (this.user.roles.includes(USER_ROLES.PARTNER)) {
        this.addUserProcess();
      }
      if (this.user.roles.includes(USER_ROLES.DISTRIBUTOR)) {
        this.addDistributorProcess();
      }
      this.finishProcess();
    });
  }


  private updateProcess() {
    this.service.repo.updateUser(this.user).subscribe(async result => {
      await this.refreshConnections();
      this.finishProcess();
    });
  }

  private async refreshConnections() {
    if (this.user.partners.length > 0) {
      await this.removeUserProcess();
    }
    if (this.user.distributors.length > 0) {
      await this.removeDistributorProcess();
    }

    if (this.user.roles.includes(USER_ROLES.PARTNER)) {
      await this.addUserProcess();
    }
    if (this.user.roles.includes(USER_ROLES.DISTRIBUTOR)) {
      await this.addDistributorProcess();
    }
  }

  private async removeUserProcess() {
    for (let partner of this.user.partners) {
      await lastValueFrom(this.partnerService.repo.removeUsers(partner.id, [this.user.keycloakId]));
    }

  }

  private async addUserProcess() {
    for (let id of this.userPartner) {
      await lastValueFrom(this.partnerService.repo.addUsers(id, [new PartnerUser(this.user.keycloakId)]));
    }
  }

  private async removeDistributorProcess() {
    for (let distributor of this.user.distributors) {
      await lastValueFrom(this.distributorService.repo.removeUsers(distributor.id, [this.user.keycloakId]))
    }
  }

  private async addDistributorProcess() {
    for (let id of this.userDistributor) {
      await lastValueFrom(this.distributorService.repo.addUsers(id, [new DistributorUser(this.user.keycloakId)]));
    }

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  openAddAddressModal(): () => void {
    return () => {
      this.componentDialogRef = this.dialogService.open(
        AddAddressToModelComponent,
        Utilities.getDefaultModalConfig(this.user)
      );
      this.componentDialogRef.afterClosed().subscribe(result => {
        if (result != 'cancel') {
          this.user.address = result;
        }
      });

    };
  }


  check($event: NxCheckboxGroupChangeEvent, index: number) {
    const count = $event.value.map(v => parseInt(v)).reduce((total, current) => {
      return total + current;
    }, 0);
    this.user.chmod[index] = count.toString();
  }


}

