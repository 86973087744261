import {BaseModel} from "./base.model";
import {Partner} from "./partner.model";

export class Webhook implements BaseModel {
  id: number;
  type: WebhookType;
  securityKey: string;
  url: string;
  signatureUrl: string;
  partner: Partner;
  partnerId: number;
}

export enum WebhookType {
  STATISTIC = 'STATISTIC',
  CIRCUS = 'CIRCUS',
  VEHICLE = 'VEHICLE',
  BICYCLE = 'BICYCLE',
  BUS = 'BUS',
  TRUCK = 'TRUCK'
}

export function copyWebhook(original: Webhook): Webhook {
  const copy = new Webhook();

  copy.id = original.id;
  copy.type = original.type;
  copy.securityKey = original.securityKey;
  copy.url = original.url;
  copy.signatureUrl = original.signatureUrl;
  copy.partner = original.partner;
  copy.partnerId = original.partnerId;

  return copy;
}
