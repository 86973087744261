<nx-card class="table-card-viewer">
  <page-list-view-header
    [newSupplier]="new()"
    [title]="headerTitle"
    [(viewChangers)]="viewChangers"
    [menuWantedInMobileView]="true"
    [onlyAddNewButtonWantedInMobileView]="false"
  ></page-list-view-header>

  <div nxRow="">
    <div nxCol="12">
      <ng-container *ngIf="!viewChangers['calendarView'].show">
        <div class="aquila-table-custom">
          <aquila-table [rows]="models" [colDefinitions]="colDef" [actionConfig]="actionDef"
                        [showAsList]="mobile"
          ></aquila-table>
        </div>
      </ng-container>
      <!--      <ng-container *ngIf="viewChangers['mapView'].show">-->
      <!--        <app-map [models]="mapModels" [startZoom]="8" [markerClickSupplier]="markerClick()" [showYourPosition]="false"></app-map>-->
      <!--      </ng-container>-->
      <ng-container *ngIf="viewChangers['calendarView'].show">
        <app-gant-chart *ngIf="models" #gantChartComponent [cookieKey]="GANT_FILTER_COOKIE_KEY" [options]="options"
                        [models]="models" [service]="service"></app-gant-chart>
      </ng-container>
    </div>
  </div>
</nx-card>


<ng-template #test let-value="value">
  <ng-container *ngFor="let role of value">
    {{ role }},
  </ng-container>
</ng-template>


