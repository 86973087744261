import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../../../../models/user.model";
import {ActivatedRoute} from "@angular/router";
import {lastValueFrom} from "rxjs";
import {UserService} from "../../../../services/model/user.service";
import {PageTest} from "../../../../utilities/PageTest";
import {ROUTING} from "../../../../utilities/routing-constants";
import {AbstractShowPage} from "../../../../models/AbstractShowPage";
import {PartnerService} from "../../../../services/model/partner.service";
import {MyStorageService} from "../../../../services/my-storage.service";
import {DistributorLayoutComponent} from "../../../../layouts/distributor-layout/distributor-layout.component";

@Component({
  selector: 'app-show-users-for-distributor',
  templateUrl: './show-users-for-distributor.component.html',
  styleUrls: ['./show-users-for-distributor.component.scss']
})

@PageTest({
  path: 'show/:email',
  pathPrefix: ROUTING.User.basePlural,
  layout: DistributorLayoutComponent
})
export class ShowUsersForDistributorComponent extends AbstractShowPage<User, UserService> implements OnInit, OnDestroy {


  constructor(service: UserService, route: ActivatedRoute,
              myStorageService:MyStorageService, private partnerService: PartnerService) {
    super(service, route, myStorageService);
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(async params => {
      this.model = await lastValueFrom(this.service.getById(params['email']));
      if (this.model == null) {
        this.service.notificationService.swalAlertPromise('warning', 'general.warning', 'error.userNotExist').then((result: any) => {
          this.service.navigateToBase();
        });
      }
      if (this.model.keycloakId) {
        this.model.partners = await lastValueFrom(this.partnerService.getPartnersForUser(this.model.keycloakId));
      }
    });

  }

  ngOnDestroy(): void {
    this.onDestroy()
  }

  override edit() {
    return () => this.service.navigateToUrl('distributor/users/edit/' + this.model.email);
  }

  override back() {
    return () => this.service.navigateToUrl('distributor/users');
  }

}

