import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from "../../../models/user.model";
import {DISTRIBUTOR_KEY, LIMITED_ROLES, ROLE_INDEX, ROLES} from "../../../utilities/constants";
import {lastValueFrom} from "rxjs";
import {PartnerService} from "../../../services/model/partner.service";
import {AbstractBaseComponent} from "common";
import {UserFormComponent} from "../user-form/user-form.component";
import {DistributorService} from "../../../services/model/distributor.service";
import {MyStorageService} from "../../../services/my-storage.service";

@Component({
  selector: 'forms-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent extends AbstractBaseComponent implements OnInit {
  @Input() parent:UserFormComponent;
  @Input() user: User;
  @Input() userPartner: any[];
  @Input() userDistributor: any[];
  @Output() userPartnerChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() userDistributorChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() roles: any[] = [];
  @Input() displayForDistributors: boolean = false;

  roleIndex = ROLE_INDEX;
  staticRoles: string[] = ROLES;
  partners: any[] = [];
  distributors: any[] = [];
  distributorNamesForTheCurrentUser: string[] = [];

  constructor(
    private partnerService: PartnerService,private distributorService:DistributorService, private myStorageService: MyStorageService) {
    super();
  }

  ngOnInit(): void {
    this.loadRoles();
  }

  private async loadRoles(): Promise<void> {
    this.partners = await lastValueFrom(this.partnerService.getAllByDeleted('SlimPartnerDTO'));
    this.distributorNamesForTheCurrentUser = this.myStorageService.getFromCookies(DISTRIBUTOR_KEY, []).map(distributor => distributor.name);
    this.distributors = await lastValueFrom(this.distributorService.getAllByDeleted('SlimDistributorDTO'));

    if (this.user.keycloakId) {
      this.user.partners = await lastValueFrom(this.partnerService.getPartnersForUser(this.user.keycloakId));
      this.user.distributors = await lastValueFrom(this.distributorService.getDistributorForUserAsAdmin(this.user.keycloakId));
      this.userPartner = this.user.partners.map(partner => partner.id);
      this.userDistributor = this.user.distributors.map(distributor => distributor.id);
      this.userPartnerChange.emit(this.userPartner);
      this.userDistributorChange.emit(this.userDistributor);
    }
  }

  setDefaultChmod() {
    this.roles.map(role => role.name).forEach(role => {
      if (this.user.roles.includes(role)) {
        const chmod = this.user.chmod[this.roleIndex[role]];
        if (chmod == '0') {
          this.user.chmod[this.roleIndex[role]] = '4';
        }
      } else {
        this.user.chmod[this.roleIndex[role]] = '0';
      }
    });
  }

  changeUserPartner() {
    this.userPartnerChange.emit(this.userPartner);
  }
  changeUserDistributor() {
    this.userDistributorChange.emit(this.userDistributor);
  }

  roleEditDisabled(role: string) {
    return this.displayForDistributors && !LIMITED_ROLES.includes(role);
  }

  roleSelectDisabled() {
    return (role: string) => this.roleEditDisabled(role);
  }

  distributorEditDisabled(distributor: any) {
    return this.displayForDistributors && !this.distributorNamesForTheCurrentUser.includes(distributor.name);
  }

  distributorSelectDisabled() {
    return (distributor: any) => this.distributorEditDisabled(distributor);
  }
}
