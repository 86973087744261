<ng-container *ngIf="model">
  <nx-card class="p-0 w-100">
    <nx-card-header>
      <page-show-tool-bar
        [title]="model.firstName+ ' ' +model.lastName"
        [backSupplier]="back()"
        [editSupplier]="edit()"
      ></page-show-tool-bar>
    </nx-card-header>

    <div nxRow="" class="nx-margin-top-s">
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'general.name' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.firstName + ' ' + model.lastName}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'user.email' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.email}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'user.userName' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.userName}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'user.phone' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.phone}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'user.partners' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.partners  | joint:'name'}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
      <div nxCol="12,12,6,4,3">
        <nx-card class="box">
          <p>{{'user.roles' | caption}}</p>
          <nx-card-header>
            <h3 class="nx-margin-y-s">
              {{model.roles | joint}}
            </h3>
          </nx-card-header>
        </nx-card>
      </div>
    </div>
  </nx-card>
</ng-container>

